<template>
	<div>
		<el-form :inline="true" @submit.native.prevent @keyup.enter.native.prevent="search">
			<el-form-item label="退款类型：">
				<el-select v-model="formData.type" placeholder="请选择" style="width: 100px;" clearable>
					<el-option
						v-for="item in types"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="订单编号：">
				<el-input v-model="formData.orderId" placeholder="订单编号" clearable></el-input>
			</el-form-item>
			<el-form-item label="退款时间：">
				<el-date-picker v-model="formData.timeRange" type="daterange" align="right" unlink-panels
					range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
				</el-date-picker>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="search">查询</el-button>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="exportExcel">创建Excel导出任务</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="dataList" border style="width: 100%;">
			<el-table-column type="index" width="50" align="center" label="序号"> </el-table-column>
			<el-table-column prop="type" header-align="center" align="center" label="退款单类型">
				<template slot-scope="scope">
					{{
						scope.row.type === 1 ? '药品单' : '问诊单'
					}}
				</template>
			</el-table-column>
			<el-table-column prop="status" header-align="center" align="center" label="退款状态">
				<template slot-scope="scope">
					{{
						scope.row.status === 0 ? '待处理' : '已退款'
					}}
				</template>
			</el-table-column>
			<el-table-column prop="refundFee" header-align="center" align="center" label="退款金额（元）"></el-table-column>
			<el-table-column prop="createdAt" header-align="center" align="center" label="记录创建时间"></el-table-column>
			<el-table-column prop="remark" header-align="center" align="center" label="备注"></el-table-column>
			<el-table-column header-align="center" align="center" width="150" label="操作">
				<template slot-scope="scope">
					<el-popconfirm v-if="scope.row.status === 0" title="确认进行退款？"
						@confirm="refund(scope.row)">
						<el-button style="margin-left: 10px" slot="reference" type="text" size="small">退款</el-button>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<div style="float: right; margin-top: 20px;">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
	</div>
</template>
<script>
export default {
	data () {
		return {
			types: [
				{ label: '问诊单', value: 0 },
				{ label: '处方单', value: 1 }
			],
			formData: {
				type: '',
				orderId: '',
				timeRange: []
			},
			dataList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0
		};
	},
	created () {
		this.search();
	},
	methods: {
		search () {
			this.currentPage = 1;
			this.getData();
		},
		getData () {
			this.$root.request('refundRecordList', {
				...this.formData,
				startTime: this.formData.timeRange[0],
				endTime: this.formData.timeRange[1],
				page: this.currentPage,
				limit: this.pageSize
			}).then((data) => {
				if (data) {
					this.total = data.count;
					this.dataList = data.rows;
				}
			});
		},
		refund (item) {
			this.$root.request('refundExecute', {
				id: item.id,
				out_trade_no: item.orderId,
				out_refund_no: item.outRefundNo,
				total_fee: item.type ? item.appTotalFee : item.conTotalFee,
				refund_fee: item.refundFee,
				refund_desc: item.remark
			}).then((data) => {
				if (data && data.ok === 1) {
					this.$root.elMsg('操作成功');
					this.getData();
				}
			});
		},
		handleSizeChange (size) {
			this.pageSize = size;
			this.currentPage = 1;
			this.getData();
		},
		handleCurrentChange (page) {
			this.currentPage = page;
			this.getData();
		},
		exportExcel () {
			this.$prompt('请输入Excel文件名', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(({ value }) => {
				this.$root.request('exportExcelTask', {
					name: value,
					apiName: '/mnt/refundRecord/list',
					headerModel: [
						{ header: '退款单类型', key: 'type', width: 18 },
						{ header: '退款状态', key: 'status', width: 18 },
						{ header: '退款金额（元）', key: 'refundFee', width: 18 },
						{ header: '记录创建时间', key: 'createdAt', width: 30 },
						{ header: '备注', key: 'remark', width: 30 }
					],
					dataFormatMap: {
						type: {
							0: '问诊单',
							1: '药品单'
						},
						status: {
							0: '待处理',
							1: '已退款'
						}
					},
					options: {
						type: this.formData.type,
						orderId: this.formData.orderId,
						startTime: this.formData.timeRange[0],
						endTime: this.formData.timeRange[1]
					}
				}).then(data => {
					if (data && data.ok === 1) {
						this.$root.elMsg('操作成功');
					}
				});
			});
		}
	}
};
</script>
<style lang="less" scoped>

</style>